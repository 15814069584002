import React, { useState } from "react";
import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import "./ReportDirectorFilter.scss"


const ReportDirectorFilter = (props) => {
    const [groupName, setGroupName] = useState(
        props.groups?.length === 1
            ? props.groups[0].name
            : undefined);
    
    const [subGroupName, setSubGroupName] = useState(
        (props.groups?.length === 1
            && props.groups[0].subGroups.length === 1)
            ? props.groups[0].subGroups[0].name
            : undefined);
    
    const [directorName, setDirectorName] = useState(
        (props.groups?.length === 1
            && props.groups[0].length === 1
            && props.groups[0].subGroups[0].directors.length === 1)
            ? props.groups[0].subGroups[0].directors[0].name
            : undefined);
    
    const updateFilteredEntries = () => {
        const filtered = props.groups
            .filter(group => !groupName || group.name === groupName)
            .map(group => ({
                ...group,
                subGroups: group.subGroups
                    .filter(subGroup => !subGroupName || subGroup.name === subGroupName)
                    .map(subGroup => ({
                        ...subGroup,
                        directors: subGroup.directors
                            .filter(director => !directorName || director.name === directorName)
                    }))
            }));

        props.updateFilteredEntries && props.updateFilteredEntries(filtered)
    };

    const updateGroupName = name => {
        setGroupName(name);
        setSubGroupName(undefined);
        setDirectorName(undefined);
        updateFilteredEntries();
    }

    const updateSubGroupName = name => {
        setSubGroupName(name);
        setDirectorName(undefined);
        updateFilteredEntries();
    }

    const updateDirectorName = name => {
        setDirectorName(name);
        updateFilteredEntries();
    }

    return (!!props.groups?.length) && (
        <section className="report-director-filter">
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for="groupChoice">Group</Label>
                        <Input
                            id="groupChoice"
                            name="groupChoice"
                            type="select"
                            onChange={event => updateGroupName(event.target.value)}
                            readOnly={props.groups.length === 1}
                        >
                            {props.groups.length > 1 &&
                                <option value="" key="all-groups">All groups</option>}
                            
                            {props.groups.sort((left, right) => left.name < right.name ? -1 : (right.name < left.name ? 1 : 0) )
                                .map(group =>
                                    <option value={group.name} key={group.name}>{group.name}</option>)}
                        </Input>
                    </FormGroup>
                </Col>
                {groupName && <Col md={4}>
                    <FormGroup>
                        <Label for="subGroupChoice">Sub-Group</Label>
                        <Input
                            id="subGroupChoice"
                            name="subGroupChoice"
                            type="select"
                            onChange={event => updateSubGroupName(event.target.value)}
                            readOnly={props.groups.find(group => group.name === groupName).subGroups.length === 1}
                        >
                            {props.groups.find(group => group.name === groupName).subGroups.length > 1 &&
                                <option value="" key="all-sub-groups">All sub-groups</option>}
                            
                            {props.groups.find(group => group.name === groupName)
                                .subGroups.sort((left, right) => left.name < right.name ? -1 : (right.name < left.name ? 1 : 0))
                                    .map(subGroup =>
                                    <option value={subGroup.name} key={subGroup.name}>{subGroup.name}</option>)}
                        </Input>
                    </FormGroup>
                </Col>}
                {subGroupName && <Col md={4}>
                    <FormGroup>
                        <Label for="directorChoice">Director</Label>
                        <Input
                            id="directorChoice"
                            name="directorChoice"
                            type="select"
                            onChange={event => updateDirectorName(event.target.value)}
                            readOnly={props.groups.find(group => group.name === groupName)
                                .subGroupsByName[subGroupName].directors.length === 1}
                        >
                            {props.groups.find(group => group.name === groupName)
                                .subGroupsByName[subGroupName].directors.length > 1 &&
                                <option value="" key="all-directors">All directors</option>}
                            
                            {props.groups.find(group => group.name === groupName)
                                .subGroupsByName[subGroupName].directors.sort((left, right) => left.name < right.name ? -1 : (right.name < left.name ? 1 : 0))
                                    .map(director =>
                                    <option value={director.name} key={director.name}>{director.name}</option>)}
                        </Input>
                    </FormGroup>
                </Col>}
            </Row>
        </section>
    )
}
// group.name
// subGroup.name

export default ReportDirectorFilter;

import React from "react";
import "./Report.scss"
import logo from "../../assets/fs-logo.svg"

const ArrearsReport = (props) => {
    const currencyFormatter = new Intl.NumberFormat(
        navigator.languages || navigator.language, {
        style: 'currency',
        currency: 'GBP'
    })

    if (!props.groups) return <></>

    return props.groups
        .filter(group => group.remittanceCount > 0)
        .map(group =>
            <section className="report-group report-application-update">
                {props.groups.length > 1
                    ? <h2 key={`${group.name}-heading`}>{group.name}</h2>
                    : <></>
                }
                {group.subGroups
                    .filter(subGroup => subGroup.remittanceCount > 0)
                    .map(subGroup =>
                        <section className="sub-group">
                            {group.subGroups.length > 1
                                ? <h3>{subGroup.name}</h3>
                                : <></>
                            }
                            {subGroup.directors
                                .filter(director => director.remittances.length > 0)
                                .map(director =>
                                    <section className="report-summary">
                                        <section className="report-heading">
                                            <img src={logo} alt="FuneralSafe logo" />
                                            <section className="report-scope">
                                                <h4>Arrears Report</h4>
                                                <dl>
                                                    <dt>Account Name:</dt>
                                                    <dd>{director.name}</dd>
                                                </dl>
                                            </section>
                                        </section>
                                        <section className="report-body" key={director.name + '-body'}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="number">Customer ID</th>
                                                        <th>Customer Name</th>
                                                        <th className="number">Loan Amount</th>
                                                        <th className="number">Payments In Arrears</th>
                                                        <th className="number">Amount Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {director.remittances.map((application, index) => {
                                                        const loanAmount = application["LoanValue"] && Number.parseFloat(application["LoanValue"]);
                                                        const arrearsValue = application["ArrearsValue"] && Number.parseFloat(application["ArrearsValue"]);

                                                        return <tr>
                                                            <td className="number">{application["ClientId"]}</td>
                                                            <td>{`${application["AppFirstName"]} ${application["AppLastName"]}`}</td>
                                                            <td className="number">{loanAmount && currencyFormatter.format(loanAmount)}</td>
                                                            <td className="number">{application["PaymentsInArrears"]}</td>
                                                            <td className="number">{arrearsValue && currencyFormatter.format(arrearsValue)}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </section>
                                    </section>
                                )}
                        </section>
                    )}
            </section>
        );
}

export default ArrearsReport;

import React from "react";
import { Outlet } from "react-router-dom";

import NavBar from "./components/NavBar";

const Layout = () => <React.StrictMode>
    <NavBar />
    <section className='container'>
      <Outlet />
    </section>
</React.StrictMode>
  
export default Layout;
import React, { Fragment } from "react";
import "./Report.scss"
import logo from "../../assets/fs-logo.svg"

const ApplicationUpdate = (props) => {
    const dateFormatter = new Intl.DateTimeFormat(
        navigator.languages || navigator.language, {
        dateStyle: 'medium',
    })
    const dateTimeFormatter = new Intl.DateTimeFormat(
        navigator.languages || navigator.language, {
        dateStyle: 'medium',
        timeStyle: 'short'
        // hour: "numeric",
        // minute: "numeric"
    })
    const currencyFormatter = new Intl.NumberFormat(
        navigator.languages || navigator.language, {
        style: 'currency',
        currency: 'GBP'
    })

    if (!props.groups || !props.startDate) return <></>

    const startDate = new Date(Date.parse(props.startDate));
    const endDate = props.endDate && new Date(Date.parse(props.endDate));
    
    return props.groups
        .filter(group => group.remittanceCount > 0)
        .map(group =>
            <section className="report-group report-application-update">
                {props.groups.length > 1
                    ? <h2 key={`${group.name}-heading`}>{group.name}</h2>
                    : <></>
                }
                {group.subGroups
                    .filter(subGroup => subGroup.remittanceCount > 0)
                    .map(subGroup =>
                        <section className="sub-group">
                            {group.subGroups.length > 1
                                ? <h3>{subGroup.name}</h3>
                                : <></>
                            }
                            {subGroup.directors
                                .filter(director => director.remittances.length > 0)
                                .map(director =>
                                    <section className="report-summary">
                                        <section className="report-heading">
                                            <img src={logo} alt="FuneralSafe logo" />
                                            <section className="report-scope">
                                                <h4>Application Update</h4>
                                                <dl>
                                                    <dt>Date:</dt>
                                                    <dd>{
                                                        endDate && endDate > startDate
                                                            ? `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`
                                                            : dateFormatter.format(startDate)}</dd>
                                                    <dt>Account Name:</dt>
                                                    <dd>{director.name}</dd>
                                                </dl>
                                            </section>
                                        </section>
                                        <section className="report-body" key={director.name + '-body'}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="number">Application ID</th>
                                                        <th className="date">Application Date</th>
                                                        <th>Name</th>
                                                        <th className="reference">Reference</th>
                                                        <th className="number">Loan Amount</th>
                                                        <th>Decision</th>
                                                        <th>Decline reason</th>
                                                        <th>Signed</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {director.remittances.map((application, index) => {
                                                        const applicationDate = application["Application Date"] && new Date(Date.parse(application["Application Date"]));
                                                        const signedDate = application["Signed"] && new Date(Date.parse(application["Signed"]));
                                                        const loanAmount = application["Loan Amount"] && Number.parseFloat(application["Loan Amount"]);

                                                        return <tr key={application["Application ID"]}>
                                                            <td className="number">{application["Application ID"]}</td>
                                                            <td className="date">{applicationDate && dateTimeFormatter.format(applicationDate)}</td>
                                                            <td>{application["Name"]}</td>
                                                            <td className="number">{application["Reference"]}</td>
                                                            <td className="number">{loanAmount && currencyFormatter.format(loanAmount)}</td>
                                                            <td>{application["Decision"]}</td>
                                                            <td>{application["Decline reason"]}</td>
                                                            <td className="date">{signedDate && dateFormatter.format(signedDate)}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </section>
                                    </section>
                                )}
                        </section>
                    )}
            </section>
        );
}

export default ApplicationUpdate;

import configJsonProd from "./auth_config.production.json";
import configJsonDev from "./auth_config.json";

export function getConfig() {
  const configJson = process.env.NODE_ENV === 'production'
    ? configJsonProd
    : configJsonDev;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    reportsUri: configJson.reportsUri,
    usersUri: configJson.usersUri,
    directorsUri: configJson.directorsUri
  };
}

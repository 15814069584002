import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";

export const Users = () => {
    return <>
      users go here
  </>
};

export default withAuthenticationRequired(Users, {
  onRedirecting: () => <Loading />,
});

import React, { useState } from "react";
import {Col, FormGroup, Label, Row} from 'reactstrap'
import { DatePicker } from 'reactstrap-date-picker'
import "./ReportDateFilter.scss"


const ReportDateFilter = (props) => {
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);

    function updateStartDate(newDate) {
        setStartDate(newDate);
        props.onDateRangeUpdated(newDate, endDate);
    }

    function updateEndDate(newDate) {
        setEndDate(newDate);
        props.onDateRangeUpdated(startDate, newDate);
    }

    return (
        <section className="report-date-filter">
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Start Date</Label>
                        <DatePicker id="date-picker-start"
                            value={startDate}
                            onChange={updateStartDate} />
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label>End Date (Leave empty for one day)</Label>
                        <DatePicker id="date-picker-end"
                            value={endDate}
                            onChange={updateEndDate} />
                    </FormGroup>
                </Col>
            </Row>
        </section>
    )
}

export default ReportDateFilter;

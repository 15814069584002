import React, { Fragment } from "react";
import "./Report.scss"
import logo from "../../assets/fs-logo.svg"

const ApplicationDashboard = (props) => {
    const dateFormatter = new Intl.DateTimeFormat(
        navigator.languages || navigator.language, {
        dateStyle: 'medium',
    })
    const dateTimeFormatter = new Intl.DateTimeFormat(
        navigator.languages || navigator.language, {
        dateStyle: 'medium',
        timeStyle: 'short'
        // hour: "numeric",
        // minute: "numeric"
    })
    const currencyFormatter = new Intl.NumberFormat(
        navigator.languages || navigator.language, {
        style: 'currency',
        currency: 'GBP'
    })

    if (!props.groups || !props.startDate) return <></>

    const startDate = new Date(Date.parse(props.startDate));
    const endDate = props.endDate && new Date(Date.parse(props.endDate));
    
    return props.groups
        .filter(group => group.remittanceCount > 0)
        .map(group =>
            <section className="report-group report-application-dashboard">
                {props.groups.length > 1
                    ? <h2 key={`${group.name}-heading`}>{group.name}</h2>
                    : <></>
                }
                {group.subGroups
                    .filter(subGroup => subGroup.remittanceCount > 0)
                    .map(subGroup =>
                        <section className="sub-group">
                            {group.subGroups.length > 1
                                ? <h3>{subGroup.name}</h3>
                                : <></>
                            }
                            {subGroup.directors
                                .filter(director => director.remittances.length > 0)
                                .map(director =>
                                    <section className="report-summary">
                                        <section className="report-heading">
                                            <img src={logo} alt="FuneralSafe logo" />
                                            <section className="report-scope">
                                                <h4>Application Dashboard</h4>
                                                <dl>
                                                    <dt>Date:</dt>
                                                    <dd>{
                                                        endDate && endDate > startDate
                                                            ? `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`
                                                            : dateFormatter.format(startDate)}</dd>
                                                    <dt>Account Name:</dt>
                                                    <dd>{director.name}</dd>
                                                </dl>
                                            </section>
                                        </section>
                                        <section className="report-body" key={director.name + '-body'}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="number">Customer Number</th>
                                                        <th className="date">App received</th>
                                                        <th>Customer Name</th>
                                                        <th className="date">Loan Issued</th>
                                                        <th className="date">Loan Declined</th>
                                                        <th>Decline Reason</th>
                                                        <th className="number">Loan Value</th>
                                                        <th className="date">First repayment</th>
                                                        <th className="number">Number of missed payments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {director.remittances.map((application) => {
                                                        const appReceived = application["App received"] && new Date(Date.parse(application["App received"]));
                                                        const loanIssued = application["Loan Issued"] && new Date(Date.parse(application["Loan Issued"]));
                                                        const loanDeclined = application["Loan Declined"] && new Date(Date.parse(application["Loan Declined"]));
                                                        const firstRepayment = application["First repayment"] && new Date(Date.parse(application["First repayment"]));
                                                        const loanValue = application["Loan Value"] && Number.parseFloat(application["Loan Value"]);
                                                        return <tr key={application["Customer Number"]}>
                                                            <td className="number">{application["Customer Number"]}</td>
                                                            <td className="date">{appReceived && dateTimeFormatter.format(appReceived)}</td>
                                                            <td>{application["Customer Name"]}</td>
                                                            <td className="date">{loanIssued && dateTimeFormatter.format(loanIssued)}</td>
                                                            <td className="date">{loanDeclined && dateTimeFormatter.format(loanDeclined)}</td>
                                                            <td>{application["Decline Reason"]}</td>
                                                            <td className="number">{loanValue && currencyFormatter.format(loanValue)}</td>
                                                            <td className="date">{firstRepayment && dateTimeFormatter.format(firstRepayment)}</td>
                                                            <td className="number">{application["Number of missed payments"]}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </section>
                                    </section>
                                )}
                        </section>
                    )}
            </section>
        );
}

export default ApplicationDashboard;
